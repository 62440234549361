.footer__container {
  padding: 100px 140px;
  background-color: var(--ghospel-gray);
  display: flex;
  justify-content: space-between;
}

.footer__title-column {
  display: flex;
  flex-direction: column;
}

.footer__column-main-title {
  color: #fff;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer__column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer__column-title {
  color: #fff;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer__column-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer__column-items-wrapper span {
  color: #fff;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iubenda-item {
  color: #fff;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
}
.footer__column-items-wrapper span {
  cursor: pointer;
}

.footer__column-icons-wrapper {
  display: flex;
  justify-content: space-between;
}

.footer__column-items-wrapper a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 768px) {
  .footer__container {
    padding: 2rem 2.5rem;
    flex-direction: column;
    gap: 2.5rem;
  }
  .footer__column-main-title {
    align-self: center;
  }
  .footer__column {
    gap: 10px;
    width: 40%;
  }
  .footer__column-items-wrapper {
    gap: 5px;
  }
  .footer__column-items-wrapper {
    word-wrap: break-word;
  }
  .footer__column-icons-wrapper {
    gap: 10px;
    justify-content: flex-end;
  }
  .footer__column-items-wrapper span {
    font-size: 10px;
  }
  .iubenda-item {
    font-size: 10px;
  }
  .footer__column-title {
    font-size: 14px;
  }
}
