.page-info-box__container {
  display: flex;
  gap: 50px;
}

.page-info-box__left-wrapper {
  align-self: center;
}

.page-info-box__left-wrapper__top-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px;
  gap: 90px;
}

.page-info-box__left-wrapper__bottom-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 90px;
}

@media screen and (max-width: 768px) {
  .page-info-box__container {
    flex-direction: column;
  }
  .page-info-box__left-wrapper__top-wrapper {
    margin-bottom: 1.5rem;
    gap: 3rem;
  }
  .page-info-box__left-wrapper__bottom-wrapper {
    gap: 3rem;
  }
}
