.default-coral-carrusel__container {
  width: calc(100% - 160px);
  padding-left: 80px;
  padding-top: 20%;
  padding-right: 80px;
  display: flex;
  position: relative;
  height: calc(60% - 20%);
  min-height: calc(60% - 20%);
  max-height: calc(60% - 20%);
}

.default-coral-carrusel__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(16, 55, 92, 0.4) 0%,
    rgba(16, 55, 92, 0.4) 100%
  );
  z-index: 100;
  transition: opacity 0.5s ease-in-out; /* Afegim una transició d'opacitat */
}
.default-coral__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.default-coral-carrusel__middle-box {
  display: flex;
  justify-content: space-between;
  z-index: 101;
  width: 100%;
}

.default-coral-carrusel__text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.default-coral-carrusel__title {
  color: #fff;
  font-family: Lora;
  font-size: 70px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.default-coral-carrusel__subtitle {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.default-coral-carrusel__ball-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 101;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.default-coral-carrusel__ball {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.default-coral-carrusel__arrow-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}

.default-carrusel__arrow:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .default-coral-carrusel__container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      255,
      0.2
    ); /* Ajusta la opacitat (0.1) segons necessitis */
  }
  .default-coral-carrusel__ball-container {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 0;
  }
  .default-coral-carrusel__middle-box {
    flex-direction: column;
    gap: 2rem;
  }
  .default-coral-carrusel__container {
    width: calc(100% - 4rem);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 75px;
    padding-bottom: 2rem;
    height: calc(60% - 75px - 2rem);
  }
  .default-coral-carrusel__title {
    font-size: 48px;
  }
  .default-coral-carrusel__subtitle {
    font-size: 36px;
  }
}
