.agenda-date__container {
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #d9d9d9;
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .agenda-date__container {
    padding: 5px 15px;
    font-size: 12px;
  }
}
