.homebox__container {
  display: flex;
  flex-direction: column;
  background-color: var(--ghospel-gray);
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 90px;
  padding-right: 90px;
  border-radius: 26px;
}

.homebox__top-wrapper {
  display: flex;
  flex-direction: column;
}

.homebox__subtitle-wrapper {
  display: flex;
  gap: 15px;
}

.homebox__subtitle {
  color: #fff;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.homebox__subtitle-line {
  height: 2px;
  width: 73px;
  background-color: #fff;
  align-self: center;
}

.homebox__title {
  color: #fff;
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.homebox__text {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  margin: 0;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .homebox__container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .homebox__subtitle-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }
  .homebox__subtitle-line {
    align-self: flex-start;
  }
  .homebox__subtitle {
    font-size: 16px;
  }
  .homebox__subtitle-line {
    height: 1px;
  }
  .homebox__title {
    font-size: 30px;
  }
  .homebox__text {
    font-size: 14px;
    margin-top: 0.5rem;
  }
}
