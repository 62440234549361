.default-coral__trajectoria__container {
  padding: var(--padding-full);
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-bottom: 0;
}

.default-coral__trajectoria__header-wrapper {
  display: flex;
  justify-content: space-between;
}

.default-coral__trajectoria__wrapper {
  display: flex;
  gap: 15px;
}

.default-coral__trajectoria__text-box {
  display: flex;
  flex-direction: column;
  background-color: var(--ghospel-gray);
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 90px;
  padding-right: 90px;
  gap: 10px;
  border-radius: 26px;
  width: calc(35% - 15px);
}

.default-coral__trajectoria__text-box__title-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.default-coral__trajectoria__text-box__subtitle {
  color: #fff;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.default-coral__trajectoria__text-box__title {
  color: #fff;
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.default-coral__trajectoria__text-box__info {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  margin: 0;
  text-align: justify;
}

.default-coral__trajectoria__images-wrapper {
  flex: 1;
  position: relative;
}

.default-coral__trajectoria__images-wrapper img {
  object-fit: cover;
  border-radius: 16px;
  position: absolute;
}

.default-coral__projects__container {
  display: flex;
  flex-direction: column;

  padding-right: 0;
  padding-bottom: 0;
}

.default-coral__events__container {
  padding: var(--padding-full);
}

@media screen and (max-width: 768px) {
  .default-coral__trajectoria__container {
    padding: 2rem;
    gap: 2rem;
  }
  .default-coral__trajectoria__wrapper {
    flex-direction: column;
  }
  .default-coral__trajectoria__text-box {
    width: calc(100% - 3rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .default-coral__trajectoria__images-wrapper {
    flex: none;
  }
  .default-coral__events__container {
    padding: 2rem 2rem;
  }
}
