.carusel-card__container {
  width: 22%;
  position: relative;
  min-width: 360px;
}

.carusel-card__container:hover {
  cursor: pointer;
}

.carusel-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 26px;
}

.carusel-card__text-wrapper {
  width: calc(70% - 16px);
  height: calc(16% - 16px + 8px);
  background: rgba(181, 33, 36, 0.88);
  position: absolute;
  bottom: 6%;
  left: 4.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px;
  gap: 8px;
  width: calc(100% - 28px);
  left: 0;
}

.carusel-card__ubi-wrapper {
  display: flex;
  gap: 5px;
}

.carusel-card__title {
  color: #fff;
  font-family: Lora, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.carusel-card__location {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

@media screen and (max-width: 768px) {
  .carusel-card__text-wrapper {
    gap: 0;
  }
  .carusel-card__title {
    font-size: 14px;
  }
  .carusel-card__location {
    font-size: 12px;
    line-height: normal;
  }
  .carusel-card__ubi-wrapper {
    gap: 0;
  }
}
