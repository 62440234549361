.agenda__items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.padding-none {
  padding: 0;
}
@media screen and (max-width: 768px) {
}
