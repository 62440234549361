.page-info-box-item__container {
  display: flex;
  gap: 25px;
  align-items: flex-start;
  flex: 1;
}

.page-info-box-item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: flex-start; /* Alinea a dalt de tot */
}

.page-info-box-item__info__top {
  color: #172432;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
}

.page-info-box-item__info__bottom {
  color: #9a9a9a;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .page-info-box-item__container {
    gap: 1rem;
    justify-content: flex-end;
  }
  .page-info-box-item__info__top {
    font-size: 24px;
  }
  .page-info-box-item__info__bottom {
    font-size: 14px;
    line-height: normal;
  }
  .page-info-box-item__info {
    gap: 1px;
    width: min-content;
  }
}
