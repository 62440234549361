.director-card__container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.director-card__box-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  gap: 90px;
}

.director-card__image {
  border-radius: 100%;
  object-fit: cover;
  align-self: center;
  max-width: calc(45%);
}

.director-card__box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 45%;
  justify-content: center;
}

.director-card__box-title {
  color: var(--ghospel-gray);
  font-family: Lora;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.director-card__box-text {
  color: var(--ghospel-gray);
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 42px; */
}

.director-card__box-info {
  color: var(--ghospel-red);
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 50px;
  text-align: center;
  width: 80%;
  align-self: center;
}

@media screen and (max-width: 795px) {
  .director-card__box-wrapper {
    flex-direction: column;
  }
  .director-card__image {
    width: 100%;
    border-radius: 0;
    max-width: none;
  }
  .director-card__box-title {
    font-size: 38px;
  }
  .director-card__box-text {
    font-size: 16px;
    line-height: 1.5rem;
  }
  .director-card__box {
    max-width: none;
  }
  .director-card__box-info {
    font-size: 16px;
    margin-top: 30px;
  }
  .director-card__box {
    gap: 12px;
  }
  .director-card__box-wrapper {
    gap: 15px;
    margin-top: 40px;
  }
}
