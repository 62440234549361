.navbar__container {
  width: calc(100% - 19%);
  height: calc(75px - 30px);
  background-color: var(--ghospel-gray);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: var(--padding-left);
  padding-right: var(--padding-left);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 500;
}

.blur-background {
  background: transparent;
}
.navbar__left-wrapper {
  display: flex;
  gap: 15px;
  height: 100%;
}

.navbar__left-wrapper:hover {
  cursor: pointer;
}
.navbar__container img {
  max-height: 100%;
}

.navbar__logo-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.navbar__logo-text {
  color: #fafafa;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.navbar__right-wrapper {
  display: flex;
  gap: 30px;
}

.navbar__container span:hover {
  cursor: pointer;
}

.navbar__right-wrapper span:hover {
  border-bottom: 3px solid var(--ghospel-red);
}

.navbar__link {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 100;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  font-weight: bold;
}

.navbar__link:hover {
  border-bottom: 3px solid var(--ghospel-red);
}

@media screen and (max-width: 795px) {
  .navbar__dropdown-menu__container {
    position: fixed;
    top: 65px;
    right: 0;
    background-color: var(--ghospel-gray);
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: white;
    padding: 2rem;
    border-radius: 0 0 12px 12px;
  }

  .navbar__link:hover {
    border: none;
  }
}
