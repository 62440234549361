.carusel__container {
  display: flex;
  width: auto;
  gap: 4%;
  overflow: hidden;
  white-space: nowrap;
  transition: left 0.5s ease; /* Transició suau amb durada de 0.5 segons */
  position: relative;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .carusel__container {
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}
