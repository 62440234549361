.ong__home {
  display: flex;
  gap: 30px;
  padding: var(--padding-full);
}

.ong__home__left {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: calc(50% - 15px);
}
.ong__home__left__top-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.ong__home__left__top-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.ong__home__left__top-wrapper p {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.ong__home__button {
  display: flex;
  padding: 15px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 3px solid var(--ghospel-red);
  background: #fff;
  color: var(--ghospel-red);
  width: max-content;
}

.ong__home__button:hover {
  cursor: pointer;
  background: #fad9d9;
}
.ong__home__right {
  width: calc(50% - 15px);
}

.ong__text-box__container {
  display: flex;
  flex-direction: column;
  padding: 60px 120px;
  background-color: var(--ghospel-gray);
  width: calc(100% - 240px);
  gap: 30px;
}

.ong__text-box__text {
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: Lora;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.ong__text-box__red-line {
  width: 30%;
  height: 3px;
  background-color: var(--ghospel-red);
  align-self: center;
}

.ong__collaboration__container {
  padding: var(--padding-full);
}

@media screen and (max-width: 768px) {
  .ong__home {
    flex-direction: column;
    padding: 2rem;
    padding-top: 100px;
  }
  .ong__home__left {
    width: 100%;
  }
  .ong__text-box__container {
    padding: 2rem;
    width: calc(100% - 4rem);
    gap: 1rem;
  }
  .ong__collaboration__container {
    padding: 2rem;
  }
}
