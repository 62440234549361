.agenda-filter__container {
  display: flex;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .agenda-filter__container {
    flex-wrap: wrap;
    gap: 10px;
  }
}
