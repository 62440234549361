.section-header__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.section-header__title {
  color: var(--ghospel-gray);
  font-family: Lora;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.section-header__text {
  color: var(--ghospel-gray);
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.section-header__button-wrapper {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.section-header__button {
  height: min-content;
  color: white;
  padding: 4px 4px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.section-header__button-wrapper div:hover {
  cursor: pointer;
}

@media screen and (max-width: 795px) {
  .section-header__title {
    font-size: 40px;
  }
  .section-header__text {
    font-size: 18px;
  }
}
