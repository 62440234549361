.popup__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Color fosc semitransparent */
  z-index: 1000; /* Assegura que la capa està per sobre de tot */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.popup__wrapper {
  width: 70%;
  height: 75%;
  display: flex;
  justify-content: center;
  position: relative;
}

.popup__arrow {
  position: absolute;
  top: 50%;
  height: min-content;
  background-color: var(--ghospel-red);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__arrow:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .popup__wrapper {
    height: 50%;
  }
}
