.agenda-item__container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--ghospel-red);
  align-items: flex-start;
  padding-bottom: 20px;
}

.agenda-item__left-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.agenda-item__info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.agenda-item__title {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.agenda-item__location {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.agenda-item__title-coral {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: bold;
}

@media screen and (max-width: 768px) {
  .agenda-item__container {
    padding-bottom: 10px;
  }
  .agenda-item__left-wrapper {
    gap: 10px;
  }
  .agenda-item__info-wrapper {
    gap: 8px;
  }
  .agenda-item__title {
    font-size: 16px;
  }
  .agenda-item__location {
    font-size: 12px;
  }
  .agenda-item__title-coral {
    font-size: 18px;
  }
}
