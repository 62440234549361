.tickets-button__container {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: white;
  display: inline-block;
  line-height: normal;
  align-self: center;
  font-family: Lato, sans-serif;
}

.tickets-button__container:hover {
  cursor: pointer;
  background-color: #89191b !important;
}

.not-available:hover {
  background-color: gray !important;
}

@media screen and (max-width: 768px) {
  .tickets-button__container {
    padding: 8px 10px;
    font-size: 14px;
  }
}
