.home__container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 65px;
  position: relative;
}

.home__logo {
  height: 30%;
  opacity: 0.5;
  object-fit: cover;
  position: absolute;
  right: 1rem;
  top: 65px;
}

.home__info-box__container {
  padding: var(--padding-full);
  padding-bottom: 0;
}

.home__videos {
  padding: 2rem;
  margin-top: 100px;
}
.home__videos__wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .home__container {
    width: 100%;
    height: calc(50vh - 65px);
  }
  .home__info-box__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .home__videos {
    margin-top: 3rem;
    padding: 0;
  }
  .home__videos__wrapper {
    margin-top: 2rem;
  }
}
